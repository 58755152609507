import React, { useState } from 'react';
import { Link } from 'gatsby';
import { SiteLayout, Head } from '~/components';
import { IconArrowRight } from '~/icons';
import { IconHeaderLink } from '../../icons';
import * as recruit from '~/pages/recruit/recruit.module.scss';

const RecruitPage = () => {

  return (
    <SiteLayout>
      <Head title="採用情報" />

      <div className={recruit.hero}>
        <h1 className={recruit.hero__title}>採用情報</h1>
        <p className={recruit.hero__message}>
          会津ラボでは一緒に働く仲間を募集しています。<br />
          出社、リモート、週3バイト、フルタイムなど、自分に合った働き方を探せます。
        </p>
        <div className={recruit.nav}>
          <div className={recruit.navLink}>
            <a href="https://www.green-japan.com/company/7196" target="_blank">
              中途採用はこちら
              <IconHeaderLink className={recruit.IconLink} />
            </a>
          </div>
        </div>
      </div>

      <div className={recruit.main}>
        <section className={recruit.section}>
          <h2 className={recruit.heading} id="jump">アルバイト</h2>
          <div className={recruit.row}>
            <div className={recruit.col}>
              <dl className={recruit.definition}>
                <dt className={recruit.definition__term}>業務内容</dt>
                <dd className={recruit.definition__description}>コーディング・デバッグ・動作テスト</dd>
              </dl>
              <dl className={recruit.definition}>
                <dt className={recruit.definition__term}>応募条件</dt>
                <dd className={recruit.definition__description}>
                  基本的なプログラミング知識やスキルをお持ちの方<br />
                  未経験でも歓迎</dd>
              </dl>
              <dl className={recruit.definition}>
                <dt className={recruit.definition__term}>勤務時間</dt>
                <dd className={recruit.definition__description}>
                  営業時間（9:00-18:00）の中で相談の上決定</dd>
              </dl>
              <dl className={recruit.definition}>
                <dt className={recruit.definition__term}>休暇・休日</dt>
                <dd className={recruit.definition__description}>
                  土日祝<br />
                  その他会社カレンダーによる</dd>
              </dl>
              <dl className={recruit.definition}>
                <dt className={recruit.definition__term}>待遇・福利厚生</dt>
                <dd className={recruit.definition__description}>
                  時給：900円～2,000円（スキルと経験に応じて決定・昇給あり）<br />
                  交通費支給<br />
                  各種社会保険完備（加入条件あり）</dd>
              </dl>
              <dl className={recruit.definition}>
                <dt className={recruit.definition__term}>特記事項</dt>
                <dd className={recruit.definition__description}>
                  ・実務経験や開発実績を積める<br />
                  ・社員登用実績あり<br />
                  ・車通勤可<br />
                  ・サポート体制あり（わからないことは教えてもらえる環境）<br />
                  ・会津大学の大学生や院生が数名在籍しております</dd>
              </dl>
            </div>
          </div>

          <div className={recruit.contact}>
            <p className={recruit.contact__message}>
              ■ ご応募に関するお問い合わせは、お問い合わせフォームまたはお電話でご連絡ください。(0242-23-8285 : 担当小関)<br />
              追って面接等の日程をご連絡いたします。
            </p>
            <Link to="/contact/" className={recruit.panelMoreLink}><span>お問い合わせ</span><IconArrowRight /></Link>
          </div>
        </section>
      </div>
    </SiteLayout >
  )
}

export default RecruitPage;
